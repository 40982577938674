import i18n from '../../../i18n'

export default {
  list: [
    {
      id: 1,
      name: i18n.t('depositInCashBox'),
    }, {
      id: 2,
      name: i18n.t('payFor'),
    },
    {
      id: 3,
      name: i18n.t('depositInBank'),
    },
  ],
  getList: function () {
    return this.list
  },
}
