import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import ConsumeType from '@/model/buffet/ConsumeType'
// import PaymentColors from '@/model/common/PaymentColors'
import AccountingStatus from '@/model/bills/cashbook/AccountingStatus'
import { manageCashAccount } from '@/common/Utlis/api'

const entity = {

  orderId: {
    displayName: 'order_ID', // 订单编号
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredEdit: false,
      requiredNew: false,
    },
    form: false,
  },

  consumeTypeId: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: ConsumeType.getList,
        itemText: '_langsname',
      },
    },
    form: false,
  },

  cashAccountNote: {
    displayName: 'remark',
  },

  payLogAmount: {
    type: IKDataEntity.Types.Float,
    displayName: 'cash_amount',
    formConfig: {
      default: '',
      overwriteRule: true,
      rule: [
        v => /^[0-9]+\.{0,1}[0-9]{0,2}$/.test(v) || this.$t('pls_enter_a_right_price'),
      ],
    },
  },
  cashType: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      default: 1,
      type: {
        selectItems: AccountingStatus.getList(),
      },
      merge: false,
    },
    header: false,
  },
  updateTimestamp: {
    type: IKDataEntity.Types.Date,
    displayName: 'updated_at',
    form: false,
  },

}
const config = {

  load: async function (filter) {
    const [fromDate, toDate] = filter[0].dateFilter
    console.log(fromDate, toDate, 'i am loaded')
    return (await hillo.get('Complex.php?op=getCashInOutDetail', {
      fromDate,
      toDate,
    })).content
  },
  add: async function (item) {
    return await manageCashAccount(item.cashType !== 1 ? -item.payLogAmount : item.payLogAmount, item.cashAccountNote,
      item.cashType === 3 ? 1 : 0)
  },

}

export default IKDataEntity.ModelFactory(entity, config)
